<template>
  <header>
    <div class="head-box" :class="[classes]">
      <div class="container flex flex__justify-space-between flex__align-item-center" >
        <div class="logo"></div>
        <div class="flex flex__align-item-center flex-box">
          <ul :class="['nav', 'flex', { 'force-hidden-menu': isHiddenMenu }]">
            <li :class="[['/'].includes($route.path) ? 'is-active' : '']" @click="goTo('/')">{{$t('header.home')}}</li>
            <li @click="goTo('/about-us')" :class="[['/about-us/who','/about-us/advantage'].includes($route.path) ? 'is-active' : '']">
              {{$t('header.about')}}
              <ul class="sub-menu-list">
                <li class="sub-menu-item" @click.stop="goTo('/about-us/who')">{{$t('header.aboutUs')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/about-us/contact')">{{$t('header.contactUs')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/about-us/advantage')">{{$t('header.ourAdvantages')}}</li>
              </ul>
            </li>
            <!-- <li @click="goTo('/platform')" :class="[['/platform/MT4','/platform/MT5'].includes($route.path) ? 'is-active' : '']">
              {{$t('header.platform')}}
              <ul class="sub-menu-list">
                <li class="sub-menu-item" @click.stop="goTo('/platform/MT4')">MT4</li>
                <li class="sub-menu-item" @click.stop="goTo('/platform/MT5')">MT5</li>
              </ul>
            </li> -->
            <li @click="goTo('/deal')" :class="[['/deal/forex','/deal/metal', '/deal/energy', '/deal/exponent'].includes($route.path) ? 'is-active' : '']">
              {{$t('header.product')}}
              <ul class="sub-menu-list">
                <li class="sub-menu-item" @click.stop="goTo('/deal/forex')">{{$t('header.foreignExchange')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/deal/metal')">{{$t('header.nobleMetal')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/deal/energy')">{{$t('header.energy')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/deal/exponent')">{{$t('header.index')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/deal/DigitalCurrency')">{{$t('header.digitalCurrency')}}</li>
              </ul>
            </li>
            <!-- <li  @click="goTo('/community')" :class="[['/community/followingUp','/community/multiAccountManagement'].includes($route.path) ? 'is-active' : '']">
              {{$t('header.community')}}
              <ul class="sub-menu-list">
                <li class="sub-menu-item" @click.stop="goTo('/community/followingUp')">{{$t('header.followingUp')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/community/multiAccountManagement')">{{$t('header.multiAccountManagement')}}</li>
              </ul>
            </li> -->
            <!-- <li @click="goTo('/activity')" :class="[['/activity'].includes($route.path) ? 'is-active' : '']">{{$t('header.activity')}}</li> -->
            <!-- <li @click="goTo('/notice')" :class="[['/notice'].includes($route.path) ? 'is-active' : '']">{{$t('header.notice')}}</li> -->
            <!-- <li @click="goTo('/support')" :class="[['/support/agent','/support/fund', '/support/faq'].includes($route.path) ? 'is-active' : '']">
              {{$t('header.support')}}
              <ul class="sub-menu-list">
                <li class="sub-menu-item" @click.stop="goTo('/support/agent')">{{$t('header.nationalBroker')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/support/fund')">{{$t('header.outOfGoldIntoGold')}}</li>
                <li class="sub-menu-item" @click.stop="goTo('/support/faq')">{{$t('header.questionAnswering')}}</li>
              </ul>
            </li> -->
          </ul>
          <div class="operating flex flex__align-item-center">
            <div class="lang-select" @mouseenter="showLangSelect" @mouseleave="hideLangSelect">
              <span class="lang">{{ langText }}</span>
              <div :class="['lang-selectable', { visible: langSelectVisible }]">
              <span
                      class="lang"
                      @click="changeLanguage(langSelectableText)"
              >
                {{ langSelectableText }}
              </span>
              </div>
            </div>
            <!-- <button class="btn btn-primary" @click="$open('login', $i18n.locale)">{{$t('signIn')}}</button> -->
            <button class="btn btn-primary" @click="$open('login5', $i18n.locale)">{{$t('signIn5')}}</button>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'home-header',
  data() {
    return {
      langSelectVisible: false,
      hiddenTimeoutId: null,
      isHiddenMenu: false,
      classes: ''
    }
  },
  computed: {
    lang() {
      return this.$i18n.locale
    },

    langText() {
      const langMapping = {
        zh: '中',
        en: 'En',
      }
      return langMapping[this.lang]
    },
    langSelectableText() {
      return this.langText === 'En' ? '中' : 'En';
    },
  },
  mounted() {
    let _self = this;
    window.onscroll=function(){
      var _top =document.documentElement.scrollTop||document.body.scrollTop;
      if(_top>92){
        _self.classes = 'floating-roof'
      }else {
        _self.classes = ''
      }
    }
  },
  methods: {
    changeLanguage(lang) {
      this.$i18n.locale = (lang === '中' ? 'zh' : 'en');
      this.langSelectVisible = false;
    },
    showLangSelect(a, r) {
      this.langSelectVisible = true;
      clearTimeout(this.hiddenTimeoutId)
    },
    hideLangSelect() {
      this.hiddenTimeoutId = setTimeout(() => {
        this.langSelectVisible = false;
      }, 300);
    },
    goTo(path) {
      this.$router.push({ path });
      this.isHiddenMenu = true;
      setTimeout(() => {
        this.isHiddenMenu = false;
      }, 300);

    }
  },
}
</script>

<style scoped lang="scss">
  .head-box{
    margin-top: 52px;
    height: 40px;
  }
.container {
  margin-top: 52px;
  height: 40px;
  .logo {
    width: 100px;
    height: 36px;
    background-size: cover;
    @include bg-image('../../assets/image/logo/unity_logo');
  }
  .nav {
    & > li {
      @include cp();

      height: 40px;
      line-height: 40px;
      font-size: 16px;
      margin-right: 40px;
      color: rgba(255, 255, 255, .6);
      position: relative;
      &:hover,
      &.is-active {
        color: #fff;
      }


      &:hover .sub-menu-list {
        display: block;
        animation: fade-in .3s;
      }
    }
    @keyframes fade-in {
      from {
        opacity: 0;
      }
      to {
        opacity: 1;
      }
    }
    @keyframes fade-out {
      from {
        height: 100%;
        opacity: 1;
      }

      to {
        height: 0;
        opacity: 0;
        display: none;
      }
    }
    &.force-hidden-menu .sub-menu-list {
      display: none !important;
    }


    .sub-menu-list {
      display: none;
      position: absolute;
      padding: 10px 0;
      background-color: #fff5;
      transform: translate3d(-10px, 0, 0);
      border-radius: 3px;
      z-index: 999;

      &:before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border: 6px solid transparent;
        border-bottom-color: #fff5;
        position: absolute;
        top: -12px;
        left: 20px;
      }
      &:after {
        content: '';
        display: inline-block;
        position: absolute;
        height: 20px;
        width: 50%;
        background-color: transparent;
        top: -20px;
      }

      .sub-menu-item {
        min-width: 260px;
        padding: 8px 12px;
        font-size: 14px;
        height: 36px;
        display: flex;
        align-items: center;
        color: #fff;
        &:hover {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }
    }
  }
  .operating {
    z-index: 999;
    .lang-select {
      @include cp();
      position: relative;
      &:after {
        display: inline-block;
        content: '';
        width: 0;
        height: 0;
        right: -20px;
        top: 5px;
        border: 5px solid transparent;
        border-top-width: 6px;
        border-top-color: #fff;
        position: absolute;

      }
      .lang {
        display: inline-block;
        font-size: 12px;
        text-align: center;
        line-height: 16px;
        width: 16px;
        height: 16px;
        background-color: #fff;
        color: #0d73d6;
        border-radius: 50%;
        position: relative;
        z-index: 1;

        &-selectable {
          display: none;
          position: absolute;
          left: 0;
          top: 0;
          padding-top: 32px;
          transition: all .3s;

          &.visible {
            display: inline-block;
          }
        }
      }
    }
    .btn {
      margin-left: 56px;
      border: none;
      padding: 0 25px;
      &:last-of-type {
        /*margin-left: 20px;*/
      }
    }
  }
}
  .floating-roof{
    width: 100%;
    position: fixed;
    left: 0;
    right: 0;
    top: 0px;
    margin: auto;
    background-color: #1d1c19;
    z-index: 999;
    /*overflow: hidden;*/
    padding: 20px 0;
    height: 80px;
    .container{
      margin-top: 0;
    }
    .sub-menu-list{
      background-color: #0009 !important;
    }

  }
</style>
