<template>
  <div class="advantage">
    <div class="container">
        <h4 v-if="$i18n.locale == 'en'">{{$t('home.advantage.title')}}</h4>
        <h4 v-else>Unity Capital Markets Pty Ltd 让交易如此特别</h4>
        <!-- <img src="../../assets/image/home/advantage-title.png" alt="" srcset="" v-else/> -->
      <ul class="advantage-list flex flex__justify-space-between flex__align-item-center">
          <li class="advantage-item">
            <img src="../../assets/image/home/advantage-icon-1.png" alt="" srcset="" />
            <span>{{$t('home.advantage.service1')}}</span>
            <small>{{$t('home.advantage.describe1')}}</small>
            <button class="btn btn-primary" @click="$open('activity', $i18n.locale)">{{$t('common.learnMore')}}</button>
          </li>
          <li class="advantage-item active">
            <img src="../../assets/image/home/advantage-icon-2.png" alt="" srcset="" />
            <span>{{$t('home.advantage.service2')}}</span>
            <small>{{$t('home.advantage.describe2')}}</small>
            <div class="btn-group">
              <!-- <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t('common.registerNow')}}</button> -->
              <button class="btn btn-primary" @click="$open('signUp5', $i18n.locale)">{{$t('common.registerNow5')}}</button>
            </div>
          </li>
          <li class="advantage-item">
            <img src="../../assets/image/home/advantage-icon-3.png" alt="" srcset="" />
            <span>{{$t('home.advantage.service3')}}</span>
            <small>{{$t('home.advantage.describe3')}}</small>
            <div class="btn-group">
              <!-- <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t('common.registerNow')}}</button> -->
              <button class="btn btn-primary" @click="$open('signUp5', $i18n.locale)">{{$t('common.registerNow5')}}</button>
            </div>
          </li>
        </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'advantage',

  data() {
    return {}
  },

  computed: {
    reasonList: () =>
      Array(4)
        .fill('')
        .map(() => ({
          title: 'Global Operation',
          content:
            'Global Operations is a first-person tactical shooter video game developed ased in March 2002, following …',
        })),
  },
}
</script>

<style scoped lang="scss">
.advantage {
  background: #F5F6F8;
  .container {
    padding: 80px 0 100px;
    text-align: center;
      h4{
          width: 100%;
          height: 43px;
          font-size: 36px;
          font-family: Barlow-ExtraBold, Barlow;
          font-weight: 800;
          color: #000000;
          line-height: 43px;
      }
    h4 {
      color: #000;
      font-size: 34px;
    }
  }
}
.advantage-list {
  margin-top: 58px;
  .advantage-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 50px 0;
    width: 340px;
    height: 300px;
    border-radius: 2px;
    background-color: #fff;
    box-shadow: 0px 5px 30px 0px rgba(73, 86, 117, 0.1);
    transition: all .5s;

    span {
      font-size: 20px;
      height: 28px;
      line-height: 28px;
      font-family: GemunuLibre;
      color: #000;
      font-weight: 800;
      margin-top: 20px;
    }

    small {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, .6);
      line-height: 20px;
      margin-top: 10px;
    }

    .btn-group {
      display: flex;
      .btn {
        margin-right: 6px;
        margin-left: 6px;
        text-wrap: nowrap;
      }
    }

    .btn {
      margin-top: 40px;
    }
  }

  .active {
    background: #0D73D6;
    box-shadow: 0px 5px 30px 0px rgba(73, 86, 117, 0.4);

    span {
      color: #fff;
    }
    small {
      color: rgba(255, 255, 255, .6);
    }
    .btn-primary {
      color: #0D73D6;
      background-color: #fff;
    }
  }
}
.slider {
  background-size: cover;
  //@include bg-image('../../assets/image/home/adv-slider-bg');
  .title {
    text-align: center;
    margin-top: 96px;
    font-size: 40px;
    font-weight: 600;
    @include title-bold();
  }
  .reason-list {
    padding: 40px 0 96px 0;
    .reason-item {
      width: 282px;
      height: 270px;
      padding: 32px 24px;
      border: 1px solid var(--border);
      background-color: var(--theme-bg-opacity);
      i {
        font-size: 28px;
        color: var(--theme-primary);
      }
      .reason-item__title {
        margin-top: 24px;
        font-size: 26px;
        font-weight: bold;
        @include one-line-ellipsis();
      }
      .reason-item__content {
        display: flow-root;
        margin-top: 14px;
        font-size: 16px;
        line-height: 24px;
        font-weight: 300;
        color: var(--text-lighter);
        @include line-ellipsis(4);
      }
    }
  }
}
</style>
