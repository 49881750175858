<template>
  <div class="start-trading-slider">
    <div class="container">
      <header class="flex flex__justify-space-between">
        <h4 v-if="$i18n.locale == 'en'">
          {{$t('home.startSlider.title1')}}
          <p>{{$t('home.startSlider.title2')}}</p>
        </h4>
        <h4 class="zhTitle" v-else>
          <p><span>{{$t('home.startSlider.title1')}}</span>{{$t('home.startSlider.title2')}}</p>
        </h4>

        <!-- <img src="../../assets/image/home/step-title.png" alt="" srcset="" v-else/> -->
        <span>More<img src="../../assets/image/home/icon-right.png" alt="" srcset="" /></span>
      </header>
      <ul class="step-list">
        <li class="step-item">
          <img src="../../assets/image/home/step-01.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle1')}}</span>
        </li>
        <li class="step-item">
          <img src="../../assets/image/home/step-02.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle2')}}</span>
        </li>
        <li class="step-item">
          <img src="../../assets/image/home/step-03.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle3')}}</span>
        </li>
        <li class="step-item">
          <img src="../../assets/image/home/step-04.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle4')}}</span>
        </li>
        <li class="step-item">
          <img src="../../assets/image/home/step-05.png" alt="" srcset="" />
          <span>{{$t('home.startSlider.sliderTitle5')}}</span>
        </li>
      </ul>
      <div class="flex flex__center">
        <!-- <button class="btn btn-primary" @click="$open('signUp', $i18n.locale)">{{$t('common.registerNow')}}</button> -->
        <button class="btn btn-primary" @click="$open('signUp5', $i18n.locale)">{{$t('common.registerNow5')}}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'start-slider',
}
</script>

<style scoped lang="scss">
.start-trading-slider {
  //background: var(--start-trading-slider-bg);
  height: 640px;
  padding-top: 90px;
  @include bg-image('../../assets/image/home/start-trading-bg');
  .container {
    header {
      align-items: flex-end;
      h4{
        width: 80%;
        height: 96px;
        font-size: 40px;
        font-family: Barlow-ExtraBold, Barlow;
        font-weight: 800;
        color: #0D73D6;
        line-height: 48px;
        span{
          font-size: 90px;
          font-family: Barlow-ExtraBold, Barlow;
          font-weight: 800;
          color: #0D73D6;
          line-height: 48px;
        }
        &.zhTitle{
          display: flex;
          align-items: flex-end;
        }
      }
      p{
        color: #ffffff;
      }
      span {
        height: 22px;
        font-size: 16px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 22px;
        cursor: pointer;
        img {
          margin-left: 8px;
          vertical-align: bottom;
        }
      }
    }
    .step-list {
      margin-top: 105px;
      display: flex;
      justify-content: space-between;
      .step-item {
        width: 20%;
        display: flex;
        flex-direction: column;
        img {
          width: 74px;
          margin-bottom: 48px;
        }
        span {
          font-size: 20px;
          position: relative;
          margin-bottom: 104px;
          &:after {
            content: '';
            display: inline-block;
            width: 64px;
            height: 8px;
            background: #0D73D6;
            position: absolute;
            left: 0;
            bottom: -20px;
          }
        }
      }
    }
  }
  .btn {
    margin: 0 12px;
    white-space: nowrap;
  }
}
</style>
