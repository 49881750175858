<template>
  <div>
    <div class="container flex flex__justify-space-between">
      <div class="left">
        <div class="chart-left-1" v-if="$i18n.locale == 'en'">Your wise choice Unity Capital Markets Pty Ltd</div>
        <div class="chart-left" v-else>您的选择<br><span style="font-size: 25px;">Unity Capital Markets Pty Ltd</span></div>
        <p>
          {{ $t('home.gpbUsd.service') }}
        </p>
        <div class="btn-group">
          <!-- <button class="btn btn-primary" style="min-width: 136px;font-size: 13px;"
            @click="$open('signUp', $i18n.locale)">
            {{ $t('common.registerNow') }}
          </button> -->
          <button class="btn btn-primary" style="min-width: 136px;font-size: 13px;"
            @click="$open('signUp5', $i18n.locale)">
            {{ $t('common.registerNow5') }}
          </button>
        </div>
      </div>
      <div class="right">
        <gpb-chart></gpb-chart>
      </div>
    </div>
  </div>
</template>

<script>
import GpbChart from './gpb-chart'
export default {
  name: 'gpb-usd',

  components: {
    GpbChart,
  },

  data() {
    return {
    }
  },

  computed: {
  },

  methods: {
  },
}
</script>

<style scoped lang="scss">
.container {
  margin-top: 80px;
  margin-bottom: 100px;

  .left {
    width: 220px;

    .chart-left {
      width: 193px;
      height: 112px;
      background-size: 100%;
      background-repeat: no-repeat;
      margin-top: 33px;
      line-height: 40px;
      font-size: 30px;
      font-weight: bolder;
      color: #0D73D6;
      // @include bg-image('../../../assets/image/home/chart-left')
    }

    .chart-left-1 {
      width: 193px;
      height: 120px;
      line-height: 40px;
      font-size: 23px;
      font-weight: bolder;
      color: #0D73D6;
    }

    p {
      color: #000;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, .6);
      line-height: 22px;
      margin: 30px 0 42px;
      /*word-break: break-all;*/
      word-wrap: break-word;
    }

    .btn-group {
      display: flex;

      .btn {
        white-space: nowrap;
        padding: 0 4px;
        margin-right: 4px;
      }
    }
  }

  .right {
    box-shadow: 0px 0px 20px 0px rgba(33, 66, 171, 0.15);
  }
}
</style>
